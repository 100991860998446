const Header = ({ title, mode, pages }) => {
  const headerPages = [];

  for (const page of pages) {
    headerPages.push(<div className="header-flair-option">{page.title}</div>);
  }

  return (
    <div className="flex-me zz-top">
      {mode === "dark" ? (
        <div className="header-brand">{title}</div>
      ) : (
        <div className="header-brand-flair">{title}</div>
      )}
      <div className="flex-me">
        {headerPages}
        <div className="language-control">(En) ⌄</div>
      </div>
    </div>
  );
};

Header.defaultProps = {
  title: "Sticker Design Kit",
  mode: "dark",
  pages: [],
};

export default Header;
