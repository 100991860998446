import styled, { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap');

*{
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}

html {
    // box-sizing: border-box;
    // font-size: 62.5%;

    // @media only screen and (max-width: 1200px){
    //     font-size: 58%;
    // }
    // @media only screen and (min-width: 1980px){
    //     font-size: 70%;
    // }
}
`;

export default GlobalStyles;

export const FooterLink = styled.a`
  color: white;
  // margin-bottom: 20px;
  font-size: 18px;
  text-decoration: none;
`;

<GlobalStyles />;
