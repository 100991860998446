import flairIcon from "../../assets/images/flairIcon.svg";
import EmailInput from "../shared/EmailInput";
import Feature from "./Feature";
import SDKFooter from "../shared/Footer";
import Header from "../shared/Header";

const Flair = () => (
  <div className="flair-container">
    <div className="flair-marketing">
      <div className="left-bokeh"></div>
      <div className="right-bokeh"></div>
      <Header
        title="Flair"
        mode="light"
        pages={[
          { title: "Features" },
          { title: "Pricing" },
          { title: "Resources" },
        ]}
      ></Header>
      <br />
      <div className="flair-header-section">
        <img className="flair-white zz-top" alt="Flair" src={flairIcon}></img>
        <div className="header-text-flair zz-top">Flair is coming soon!</div>
        <div className="subheader-flair zz-top">
          We’re building the best sticker design kit for your phone.
          <br />
          Enter your email here, and we’ll let you know as soon as Flair is
          ready.
        </div>
        <EmailInput />
      </div>
    </div>
    <br />
    <Feature direction="left" />
    <Feature direction="right" />
    <Feature direction="left" />
    <Feature direction="right" />
    <Feature direction="left" />
    <br />
    <SDKFooter />
  </div>
);

export default Flair;
