import React from "react";
import { FooterLink } from "../../GlobalStyles";
import "./Footer.css";
import SnapchatLogo from "../../assets/images/snapchat-logo.svg";
import FacebookLogo from "../../assets/images/facebook-logo.svg";
import TwitterLogo from "../../assets/images/twitter-logo.svg";
import InstagramLogo from "../../assets/images/instagram-logo.svg";
import RedditLogo from "../../assets/images/reddit-logo.svg";
import SDKLogo from "../../assets/images/SDK-logo.svg";

const SDKFooter = () => (
  <div className="footer">
    <br />
    <div className="flex-me footer-container">
      <div className="footer-col">
        <div className="header-footer">COMPANY</div>
        <br />
        <FooterLink href="/flair">Check Out Flair!</FooterLink>
        <FooterLink href="/about"> About Us </FooterLink>
        <FooterLink href="https://store.stickerdesignkit.com">
          SDK Store
        </FooterLink>
        <br />
        <FooterLink href="/terms"> Terms of Service </FooterLink>
        <FooterLink href="/privacy"> Data Privacy </FooterLink>
      </div>

      <div className="footer-col">
        <div className="header-footer">FEATURES</div>
        <br />
        <FooterLink href="/for-you"> For You </FooterLink>
        <FooterLink href="/sticker-packs"> Sticker Packs </FooterLink>
        <FooterLink href="/content-library"> Content Library </FooterLink>
        <FooterLink href="/controls"> Controls </FooterLink>
        <FooterLink href="/materials"> Saved Materials </FooterLink>
        <FooterLink href="/social-sharing"> Social Sharing </FooterLink>
      </div>
      <div className="flex-col">
        <a href="/">
          <img
            alt="Sticker Design Kit"
            className="brand-img"
            src={SDKLogo}
          ></img>
        </a>
        <div className="header-brand-footer">STICKER DESIGN KIT</div>
        <div>
          <a href="https://www.snapchat.com/add/sayitwithflair">
            <img alt="Snapchat" className="social-img" src={SnapchatLogo}></img>
          </a>
          <a href="https://facebook.com/sayitwithflair">
            <img alt="Facebook" className="social-img" src={FacebookLogo}></img>
          </a>
          <a href="https://twitter.com/sayitwithflair">
            <img alt="Twitter" className="social-img" src={TwitterLogo}></img>
          </a>
          <a href="https://www.instagram.com/stickerdesignkit/">
            <img
              alt="Instagram"
              className="social-img"
              src={InstagramLogo}
            ></img>
          </a>
          <a href="https://www.reddit.com/r/Sayitwithflair/">
            <img alt="Reddit" className="social-img" src={RedditLogo}></img>
          </a>
        </div>
      </div>
    </div>
    <div className="copyright">© 2021 Sticker Design Kit</div>
  </div>
);

export default SDKFooter;
