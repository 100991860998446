import React from "react";
import { HashLink as Link } from "react-router-hash-link";

const Terms = () => (
  <div>
    <div className="header-big">Terms of Use</div>
    <div className="header-date">Effective April 12, 2021 </div>
    <div className="header-jump">
      <Link to="#privacypolicy">Jump to: Privacy Policy </Link>
    </div>
    <br />
    <div className="header-small">1. INTRODUCTION AND ACCEPTANCE</div>
    <div className="body-text-legal">
      This Terms of Use Agreement (“Terms of Use”) is entered into by and
      between Sticker Design Kit, LLC d/b/a Sticker Design Kit (“Company”, “we”,
      “us and “our”) and you, and is made effective as of the date of your use
      of this website{" "}
      <span className="bold">
        <a href="https://stickerdesignkit.com">(www.stickerdesignkit.com) </a>
      </span>
      or any Flair application (“Application”), or the date of electronic
      acceptance. These Terms of Use together with our Privacy Policy (which can
      be found at www.stickerdesignkit.com/legal, the “Privacy Policy”) and any
      additional terms set forth the general terms and conditions of your use of
      the website and the products and services purchased or accessed through
      the website or Application (individually and collectively, the
      “Services”).
    </div>
    <br />

    <div className="body-text-legal">
      PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING OUR SERVICES. BY
      VISITING OR ACCESSING ANY PART OF OUR WEBSITE (OTHER THAN TO READ THESE
      TERMS OF USE FOR THE FIRST TIME), DOWNLOADING OUR APPLICATION, PROVIDING
      INFORMATION TO US THROUGH THE WEBSITE OR APPLICATION, REGISTERING FOR A
      MEMBERSHIP (AS DEFINED HEREIN) OR USING OUR SERVICES IN ANY WAY, YOU (the
      terms “you”, “your”, and “yours” shall refer to any and all users
      (INCLUDING COMPANY USERS AND TEAM USER (AS DEFINED HEREIN)) of the
      Website, Application or Services (the “User”)) EXPRESSLY CONSENT AND AGREE
      TO BE BOUND BY AND TO COMPLY WITH THESE TERMS OF USE AND OUR PRIVACY
      POLICY. THESE TERMS OF USE AND OUR PRIVACY POLICY MAY CHANGE FROM TIME TO
      TIME AS SET FORTH IN SECTION TITLED “AMENDMENTS; ADDITIONAL TERMS”. IF YOU
      DO NOT AGREE TO BE BOUND BY THESE TERMS OF USE AND/OR THE PRIVACY POLICY,
      YOU DO NOT HAVE OUR AUTHORIZATION TO USE ANY OF THE SERVICES AND YOU MAY
      NOT ACCESS OR USE ANY PORTION OF THE WEBSITE OR APPLICATION.
    </div>
    <div className="header-small">2. INTELLECTUAL PROPERTY</div>
    <div className="body-text-legal">
      The entire contents displayed on the Website and Application (and any
      derivative works or enhancements of the same) including, but not limited
      to, all text, fonts, illustrations, files, images, software, scripts,
      graphics, photos, sounds, music, videos, information, content, materials,
      products, services, URLs, technology, documentation, and interactive
      features included with or available through our Services (collectively,
      the “Service Content”) and all intellectual property rights to the same
      are owned by us, our licensors, or both. Additionally, all trademarks,
      service marks, trade names and trade dress that may appear in our Services
      are owned by us, our licensors, or identified third parties. All Service
      Content have copyright protection as a collective work under the laws of
      the United States and other copyright laws and, except for the limited use
      rights granted to you in these Terms of Use, you shall not acquire any
      right, title or interest in our Services or any Service Content. You are
      allowed to display and, subject to any expressly stated restrictions or
      limitations relating to specific material, download portions of the
      Service Content from the different areas of the Website and/or Application
      only for non-commercial use, unless otherwise permitted. Any
      redistribution, retransmission or publication of any copyrighted material
      is strictly prohibited without the express written permission of the
      copyright owner. You agree not to use any of our logos or any other
      proprietary graphic or trademark without our express written consent. Any
      rights not expressly granted in these Terms of Use are expressly reserved.
    </div>
    <div className="header-small">3. ACCESS AND USE</div>
    <div className="body-text-legal">
      (A) Our Services are provided for your personal and commercial use except
      when our Service Content is used to create end products for sale where the
      lifetime sales of the end product for sale exceeds 400 units. An end
      product for sale can be either a digital design or physical item created
      using our Services that you and/or your client intends to sell (an “End
      Product”). You understand and agree that End Products you create using our
      Service Content must be a unique implementation of the Service Content,
      significantly different than the original piece of Service Content and
      require time, effort, and skill to produce. For example, if you
      incorporate the Service Content it into a calendar, t-shirt or mug that
      you design and sell to multiple people, that is considered an End Product.
      End Products must not be used or sold in a way that is directly
      competitive with the Service Content. End Products must not redistribute
      the Service Content to any third parties in a manner that allows for the
      extraction of the Service Content. We may offer certain portions of our
      Services at no charge and others for a one-time fee, on a subscription
      basis or under any other lawful pricing structure. In all instances, our
      Services are not being sold to you; rather, subject to these Terms of Use,
      and upon your registration for a Membership, you are being granted or
      purchasing a revocable, non-exclusive, non-transferable and limited
      license to use our Services.
      <br />
      <br />
      (B) When using our Services, you agree to comply with all applicable
      federal, state, and local laws including, without limitation, copyright
      law. You acknowledge that you do not acquire any ownership rights by
      downloading, installing or printing Service Content.
      <br />
      <br />
      (C) Furthermore, except as expressly permitted in these Terms of Use, you
      may not:
      <br />
      <br />
      (i) remove, alter, cover, or distort any copyright, trademark, or other
      proprietary rights notice we include in or through our Services or Service
      Content; (ii) circumvent, disable or otherwise interfere with our
      security-related features including, without limitation, any features that
      prevent or restrict the use of or copying of any software or other Service
      Content; (iii) use an automatic device (such as a robot or spider) or
      manual process to copy or “scrape” the Website or Service Content for any
      purpose without our express written permission; (iv) collect or harvest
      any personally identifiable information from our Services including,
      without limitation, user names, passwords, email addresses; (v) solicit
      other users to join or become members of any commercial online service or
      other organization without our prior written approval; (vi) attempt to or
      interfere with the proper working of our Services or impair, overburden,
      or disable the same; (vii) decompile, reverse engineer, or disassemble any
      portion of our software or other Service Content, or our Services; (viii)
      use network-monitoring software to determine architecture of or extract
      usage data from our Services; (ix) encourage conduct that violates any
      local, state or federal law, either civil or criminal, or impersonate
      another user, person, or entity (e.g., using another person’s Membership;
      (x) violate U.S. export laws, including, without limitation, violations of
      the Export Administration Act and the Export Administration Regulations
      administered by the Department of Commerce; or (xi) engage in any conduct
      that restricts or inhibits any other user from using or enjoying our
      Services.
      <br />
      <br />
      (D) You agree to fully cooperate with us to investigate any suspected or
      actual activity that is in breach of these Terms of Use.
      <br />
      <br />
      (E) Additionally, in connection with the use of the Application, you
      understand and agree that (i) your use of the Application is conditioned
      upon your acceptance of these Terms of Use; (ii) the Application contains
      copyrighted material, trade secrets, and other proprietary materials of
      the Company and our licensors; (iii) you will only use the Application to
      access and/or use the Services; (iv) you will not use any software or
      services in conjunction with the Service or authorized third-party
      software which modifies or reroutes, or attempts to modify or reroute, the
      Service; (v) you will not authorize any third party to access and/or use
      the Service on your behalf using any automated process such as a BOT, a
      spider or periodic caching of information stored by the Service on your
      behalf without a separate written agreement with us; (vi) you will not use
      any software or hardware that reduces the number of Users directly
      accessing or using the Service (sometimes called 'multiplexing' or
      'pooling' software or hardware); (vii) you will not lend, lease, rent or
      sublicense the Application; (viii) you will permit us to send and deliver
      updates and notifications to you as part of your use of the Application;
      (ix) you will allow the Application to automatically download and install
      updates from time to time from us which are designed to improve, enhance
      and further develop the Application and may take the form of bug fixes,
      enhanced functions, new software modules and completely new versions; and
      (x) in order to protect those proprietary materials, except as expressly
      permitted by applicable law, neither you nor a third party acting on your
      behalf will: (a) decompile, disassemble or reverse engineer the
      Application; (b) modify or create derivative works of the Application; (c)
      use the Application in any manner to provide service bureau, commercial
      time-sharing or other computer services to third parties; (d) transmit the
      Application or provide its functionality, in whole or in part, over the
      Internet or other network (except as expressly permitted above); (e) sell,
      distribute, rent, lease, sublicense or otherwise transfer the Application
      to a third party; or (f) use components of the Application to run
      applications not running on the Application.
      <br />
      <br />
      (F) Local Language Limitations. Services do not support all local
      languages. If a local language is not supported, then the Service will
      default to English only. A list of supported languages include: English,
      Spanish, French, Portuguese, Hindi, Indonesian, Russian, German, Filipino,
      and Italian (this list is subject to change). To the extent that the
      Services are used with a local language (other than English), there may be
      limitations to certain features or functionality within the Service.
    </div>
    <div className="header-small">4. USER REGISTRATION</div>
    <div className="body-text-legal">
      (A) In order to access or use some features of our Services, you may have
      to become a registered user by registering for a Membership. If you are
      under the age of thirteen, then you are not permitted to register as a
      User or otherwise submit personal information.
      <br />
      <br />
      (B) If you become a registered User, you agree to (i) provide true,
      accurate and complete registration information for yourself; (ii) provide
      any credit card or other payment information (the “Credit Card”), if
      applicable; (iii) maintain and promptly update your Membership to keep it
      true, accurate, current and complete; and (iv) authorize us and our
      affiliates to charge your Credit Card for any and all fees incurred by you
      for your use of the Services. During registration, you will create a
      username and password (a “Membership”). You are responsible for
      safeguarding and maintaining the confidentiality of your Membership. You
      are solely responsible for the activity that occurs under your Membership,
      whether or not you have authorized the activity. You agree to Contact us
      immediately if you become aware of any breach of security or unauthorized
      use of your Membership. If you provide any information that is untrue,
      inaccurate, not current or incomplete, or we have reasonable grounds to
      suspect that such information is untrue, inaccurate, not current or
      incomplete, we have the right to suspend or terminate your Membership and
      refuse any and all current or future use of the Services (or any portion
      thereof).
    </div>
    <div className="header-small">5. USER CONTENT</div>
    <div className="body-text-legal">
      (A) We may now or in the future permit Users to post, upload, transmit
      through, or otherwise make available through our Services and for use or
      display to others (collectively, “submit”) messages, text, illustrations,
      data, files, images, graphics, photos, comments, sounds, music, videos,
      information, content, and/or other materials (“User Content”). Subject to
      the rights and license you grant herein, you retain all right, title and
      interest in your User Content. We do not guarantee any confidentiality
      with respect to User Content even if it is not published through our
      Services. It is solely your responsibility to monitor and protect any
      intellectual property rights that you may have in your User Content, and
      we do not accept any responsibility for the same. You agree that we may
      reveal your identity and whatever information we know about you to any law
      enforcement agent or official in the event of legal action arising from
      any User Content posting by you.
      <br />
      <br />
      (B) You shall not submit any User Content protected by copyright,
      trademark, patent, trade secret, moral right, or other intellectual
      property or proprietary right without the express permission of the owner
      of the respective right. You are solely liable for any damage resulting
      from your failure to obtain such permission or from any other harm
      resulting from User Content that you submit.
      <br />
      <br />
      (C) You represent, warrant, and covenant that you will not submit any User
      Content that:
      <br />
      <br />
      (i) violates or infringes in any way upon the rights of others, including,
      but not limited to, any copyright, trademark, patent, trade secret, moral
      right, or other intellectual property or proprietary right of any person
      or entity;
      <br />
      <br />
      (ii) impersonates another or is unlawful, threatening, abusive, libelous,
      defamatory, invasive of privacy or publicity rights, vulgar, obscene,
      profane, pornographic, or otherwise objectionable;
      <br />
      <br />
      (iii) encourages conduct that would constitute a criminal offense, give
      rise to civil liability or otherwise violate any law;
      <br />
      <br />
      (iv) is an advertisement for goods or services or a solicitation of funds;
      <br />
      <br />
      (v) includes personal information such as messages which identify phone
      numbers, social security numbers, account numbers, addresses, or employer
      references;
      <br />
      <br />
      (vi) contains a formula, instruction, or advice that could cause harm or
      injury; or
      <br />
      <br />
      (vii) is a chain letter of any kind. Moreover, any conduct by a User that
      in our sole discretion restricts or inhibits any other User from using or
      enjoying our Services will not be permitted.
      <br />
      <br />
      (D) We will not use your User Content (e.g., images, etc.) without your
      permission. Where you have provided us permission (e.g., to share your
      image on Facebook or Instagram, display your image on our Website under a
      promotion, etc.), by submitting User Content to us, you simultaneously
      grant, or warrant that the owner has expressly granted, to us a worldwide,
      royalty-free, perpetual, irrevocable, non-exclusive, fully sublicensable,
      and transferable right and license to use, reproduce, distribute, create
      derivative works based upon (including, without limitation, translations),
      publicly display, publicly perform, transmit, and publish the User Content
      (in whole or in part) in the manner you have requested. We may exercise
      this for the full term of any copyright that may exist in such User
      Content. Furthermore, you also grant other Users permission to access your
      User Content and to use, reproduce, distribute, create derivative works
      based upon, publicly display, publicly perform, transmit, and publish your
      User Content for personal and commercial use as permitted by the
      functionality of our Services and these Terms of Use. Notwithstanding the
      foregoing, you waive any and all claims you may now or later have in any
      jurisdiction to so-called “moral rights” or rights of “droit moral” with
      respect to the User Content. <br />
      <br />
      (E) By submitting User Content, you also grant us the right, but not the
      obligation to use your biographical information including, without
      limitation, your name and geographical location in connection with
      broadcast, print, online, or other use or publication of your User
      Content; provided, however, that all such uses will be consistent with the
      terms of our Privacy Policy.
      <br />
      <br />
      (F) Pursuant to a promotion or in certain other instances, you may give us
      permission to share or use your User Content on our Website or on a third
      party platform. In such instances, you acknowledge and agree that we may
      use your User Content to promote our Services and that your User Content
      may appear in proximity to third party advertisements and/or content. You
      acknowledge and agree that no compensation will be paid to you with
      respect to the use of your User Content, as provided herein.
      <br />
      <br />
      (G) Your participation in communications through the Services, if any and
      if allowed by us, will occur in real time and is not edited, censored, or
      otherwise controlled by us. We have the right, but not the obligation, to
      monitor User Content. We have the right in our sole discretion and for any
      reason whatsoever to edit, refuse to post, remove, or disable access to
      any User Content.
    </div>
    <div className="header-small">
      6. SERVICE CONTENT & THIRD PARTY IMAGES, SOFTWARE & LINKS
    </div>
    <div className="body-text-legal">
      (A) We provide our Services including, without limitation, Service Content
      for educational, entertainment, promotional and/or commercial purposes
      expressly stated in this Terms of Use. You may not rely on any information
      and opinions expressed through any of our Services for any other purpose.
      In all instances, it is your responsibility to evaluate the accuracy,
      timeliness, completeness, or usefulness of any Service Content. We do not
      assume any responsibility or liability for any User Content, opinion or
      other commentary posted on the Website, the Application or any third party
      website linked to the Website or Application. We further do not make any
      express or implied warranty or guarantee about the accuracy, copyright
      compliance, legality, or any other aspect of the Service Content and,
      under no circumstances will we be liable for any loss or damage caused by
      your reliance on any Service Content.
      <br />
      <br />
      (B) In many instances, Service Content will include content posted by a
      third-party or will represent the opinions and judgments of a third-party.
      You understand that we do not endorse, warrant and are not responsible for
      the accuracy, timeliness, completeness, or reliability of any opinion,
      advice, or statement offered through our Services by anyone other than our
      authorized employees or spokespersons while acting in their official
      capacities. You further understand and acknowledge that you may be exposed
      to third party content that may be offensive, indecent, inaccurate, or
      objectionable, and you agree to waive, and hereby do waive, any legal or
      equitable rights or remedies you have or may have against us with respect
      thereto. <br />
      <br />
      (C) Our Services may link or contain links to other websites maintained by
      third parties. It is your responsibility when viewing other websites to
      abide by any privacy statements and terms of use posted in connection with
      these links. You understand these links may lead unintentionally to
      websites containing information that you or others may find inappropriate
      or offensive. We do not operate or control, in any respect, or necessarily
      endorse the content found on these third-party websites. You assume sole
      responsibility for your use of third-party links and agree that we are not
      responsible for any content posted on third-party websites or liable to
      you for any loss or damage of any sort incurred as a result of your
      dealings with any third-party or their website.
      <br />
      <br />
      (D) As part of our Services, you may be allowed to use certain (i)
      photographers, illustrations, or other images (“Images”) and/or (ii)
      software, widgets, or other applications (“Software”) developed, owned, or
      licensed by a third-party. Your use of these Images and Software may be
      subject to additional terms. If the Images and Software are accompanied by
      or require a license agreement from the third-party provider, you use of
      the Images and Software is subject to that license agreement, in addition
      to this Terms of Use. You may use the Images and Software solely as part
      of Services. Your use of the Images and Software is subject to the
      following: (i) You may not remove, modify, or obscure any copyright,
      trademark, or other proprietary rights notices that are contained in or on
      the Images and Software; (ii) you may not sell, modify, re-use
      reverse-engineer, decompile, disassemble, reverse compile, create
      derivative works of or attempt to derive the source code from the Images
      and Software; (iii) Company may provide your personal information to
      third-party providers as required to provide the third-party Images and
      Software; (iv) Company reserves the right to modify, change, or
      discontinue provision of the Images and Software at any time; (v) Company
      makes no representations or warranties about any third-party Images and
      Software offered in connection with Services, and expressly disclaims any
      liability.
      <br />
      <br />
      You will indemnify, defend, and hold harmless Company from and against any
      and all claims imposed upon or incurred by Company directly or indirectly
      arising from your use or misuse of the third-party Images and Software.
      The providers of the third-party Images and Software are third-party
      beneficiaries to these Terms of Use for purposes of enforcing their rights
      under these Terms of Use.
      <br />
      <br />
      The third-party providers listed in this section make no representations
      or warranties about any Images or Software offered in connection with the
      Services, and expressly disclaim any liability or damages (whether direct,
      indirect, or consequential) arising from the use of the Images or
      Software.
      <br />
      <br /> You are responsible for managing and maintaining in good standing
      any paid subscription and/or account required with a third-party provider.
      You must cancel or terminate your paid subscription and/or account with
      the applicable third-party provider and not Company.
    </div>
    <div className="header-small">7. FEES AND PAYMENT </div>
    <div className="body-text-legal">
      You agree that your Payment Method may be charged by Sticker Design Kit,
      LLC. and subject to the provisions of our Privacy Policy.
      <br />
      <br />
      (A) GENERAL TERMS, INCLUDING AUTOMATIC RENEWAL TERMS. Payment Due at Time
      of Order; Non-Refundable. You agree to pay all amounts due for Services at
      the time you order them. All amounts are non-refundable unless otherwise
      noted in the <span className="bold">Refund Policy</span>.
      <br />
      <br />
      Price Changes. Company reserves the right to change its prices and fees at
      any time, and such changes shall be posted online at this website and
      effective immediately without need for further notice to you. If you have
      purchased or obtained Services for a period of months or years, changes in
      prices and fees shall be effective when the Services in question come up
      for renewal as further described below.
      <br />
      <br />
      Payment Types. Except as prohibited in any product-specific agreement, you
      may pay for Services by using a valid credit card, provided by either
      Visa, Mastercard, Amex, or Discover each a “Payment Method”. The “Express
      Checkout” feature automatically places an order for the applicable Service
      and charges the default Express Checkout Payment Method for your Account.
      Confirmation of that order will be sent to the email address on file for
      your Account. Your Payment Method on file must be kept valid if you have
      any active Services in your Account. In addition, you agree that the
      location for the processing of your payments may change for any reason,
      including the type of Payment Method chosen, the currency selected, or
      changes or updates made to your Payment Method.
      <br />
      <br />
      Refunds Issued. You agree that where refunds are issued to your Payment
      Method, Company’s issuance of a refund receipt is only confirmation that
      Company has submitted your refund to the Payment Method charged at the
      time of the original sale, and that Company has no control over when the
      refund will be applied towards your Payment Method’s available balance.
      You further acknowledge and agree that the payment provider and/or
      individual issuing bank associated with your Payment Method establish and
      regulate the time frames for posting your refund, and that such refund
      posting time frames may range from five (5) business days to a full
      billing cycle, or longer.
      <br />
      <br />
      In the event a refund is issued to your Payment Method and the payment
      provider, payment processor or individual issuing bank associated with
      your Payment Method imposes any limitations on refunds, including but not
      limited to, limitations as to the timing of the refund or the number of
      refunds allowed, then Company, in its sole and absolute discretion,
      reserves the right to issue the refund either (i) in the form of an
      in-store credit; (ii) via issuance of a Company check, which will be sent
      to the mailing address on file for your Account; or (iii) in some
      jurisdictions, as a bank transfer, when the payment processor cannot
      refund back to the Payment Method. Company also has the right to offer an
      in-store credit for customers seeking refunds, even if there are no
      limitations on refunds imposed by the Payment Method.
      <br />
      <br />
      Monthly Billing Date. If you are being billed on a monthly basis, your
      monthly billing date will be based on the date of the month you purchased
      the Services, unless that date falls after the 28th of the month, in which
      case your billing date will be the 28th of each month.
      <br />
      <br />
      Auto-Renewal Terms. Other than as required by applicable law, Company does
      not retain hard copies or electronic versions of mandate, standing order
      or standing instruction forms and/or any signed consents relating to your
      usage of our automatic renewal services, and we are therefore unable to
      provide any such document upon request. You may view or change your
      automatic renewal settings at any time by logging into your Company
      account.
      <br />
      <br />
      IN ORDER TO ENSURE THAT YOU DO NOT EXPERIENCE AN INTERRUPTION OR LOSS OF
      SERVICES, ALL SERVICES ARE OFFERED ON AUTOMATIC RENEWAL. EXCEPT FOR
      REASONS DESCRIBED BELOW IN THIS SECTION, AUTOMATIC RENEWAL AUTOMATICALLY
      RENEWS THE APPLICABLE SERVICE UPON EXPIRATION OF THE THEN CURRENT TERM FOR
      A RENEWAL PERIOD EQUAL IN TIME TO THE MOST RECENT SERVICE PERIOD. FOR
      EXAMPLE, IF YOUR LAST SERVICE PERIOD IS FOR ONE YEAR, YOUR RENEWAL PERIOD
      WILL TYPICALLY BE FOR ONE YEAR. HOWEVER, IN THE EVENT RENEWAL WITH THE
      PAYMENT METHOD ON FILE FAILS, COMPANY MAY ATTEMPT TO RENEW THE APPLICABLE
      SERVICE FOR A PERIOD LESS THAN THE ORIGINAL SUBSCRIPTION PERIOD TO THE
      EXTENT NECESSARY FOR THE TRANSACTION TO SUCCEED.
      <br />
      <br />
      UNLESS YOU DISABLE THE AUTOMATIC RENEWAL OPTION, COMPANY WILL
      AUTOMATICALLY RENEW THE APPLICABLE SERVICE WHEN IT COMES UP FOR RENEWAL
      AND WILL TAKE PAYMENT FROM THE PAYMENT METHOD ASSOCIATED WITH THE
      SERVICE(S) IN YOUR ACCOUNT OR YOUR DESIGNATED BACKUP PAYMENT METHOD(S) ON
      FILE WITH COMPANY. IN AUTOMATICALLY RENEWING YOUR SERVICES, COMPANY WILL
      FIRST ATTEMPT TO CHARGE THE PAYMENT METHOD ASSOCIATED WITH THE SERVICE(S)
      IN YOUR ACCOUNT. IN THE EVENT COMPANY CANNOT SUCCESSFULLY CHARGE THIS
      PAYMENT METHOD, WE WILL ATTEMPT TO CHARGE THE PAYMENT METHOD(S) DESIGNATED
      AS "BACKUP" IN YOUR ACCOUNT. RENEWALS WILL BE CHARGED AT COMPANY’S THEN
      CURRENT RATES, WHICH YOU ACKNOWLEDGE AND AGREE MAY BE HIGHER OR LOWER THAN
      THE RATES FOR THE ORIGINAL SERVICE PERIOD. IN ORDER TO SEE THE RENEWAL
      SETTINGS APPLICABLE TO YOU AND YOUR SERVICES, SIMPLY LOG INTO YOUR ACCOUNT
      MANAGER FROM THIS SITE AND FOLLOW THE STEPS FOUND HERE. IF YOU DO NOT WISH
      FOR ANY SERVICE TO AUTOMATICALLY RENEW, YOU MAY ELECT TO CANCEL RENEWAL,
      IN WHICH CASE, YOUR SERVICES WILL TERMINATE UPON EXPIRATION OF THE THEN
      CURRENT TERM, UNLESS YOU MANUALLY RENEW YOUR SERVICES PRIOR TO THAT DATE.
      IN OTHER WORDS, SHOULD YOU ELECT TO CANCEL YOUR PRODUCT AND FAIL TO
      MANUALLY RENEW YOUR SERVICES BEFORE THEY EXPIRE, YOU MAY EXPERIENCE AN
      INTERRUPTION OR LOSS OF SERVICES, AND COMPANY SHALL NOT BE LIABLE TO YOU
      OR ANY THIRD PARTY REGARDING THE SAME. IN ADDITION, COMPANY MAY
      PARTICIPATE IN “RECURRING BILLING PROGRAMS” OR “ACCOUNT UPDATER SERVICES”
      SUPPORTED BY YOUR CREDIT CARD PROVIDER (AND ULTIMATELY DEPENDENT ON YOUR
      BANK’S PARTICIPATION). IF WE ARE UNABLE TO SUCCESSFULLY CHARGE YOUR
      EXISTING PAYMENT METHOD, YOUR CREDIT CARD PROVIDER (OR YOUR BANK) MAY
      NOTIFY US OF UPDATES TO YOUR CREDIT CARD NUMBER AND/OR EXPIRATION DATE, OR
      THEY MAY AUTOMATICALLY CHARGE YOUR NEW CREDIT CARD ON OUR BEHALF WITHOUT
      NOTIFICATION TO US. IN ACCORDANCE WITH RECURRING BILLING PROGRAM
      REQUIREMENTS, IN THE EVENT THAT WE ARE NOTIFIED OF AN UPDATE TO YOUR
      CREDIT CARD NUMBER AND/OR EXPIRATION DATE, COMPANY WILL AUTOMATICALLY
      UPDATE YOUR PAYMENT PROFILE ON YOUR BEHALF. COMPANY MAKES NO GUARANTEES
      THAT WE WILL REQUEST OR RECEIVE UPDATED CREDIT CARD INFORMATION. YOU
      ACKNOWLEDGE AND AGREE THAT IT IS YOUR SOLE RESPONSIBILITY TO MODIFY AND
      MAINTAIN YOUR ACCOUNT SETTINGS, INCLUDING BUT NOT LIMITED TO (I)
      CANCELLING PRODUCTS AND (II) ENSURING YOUR ASSOCIATED PAYMENT METHOD(S)
      ARE CURRENT AND VALID. FURTHER, YOU ACKNOWLEDGE AND AGREE THAT YOUR
      FAILURE TO DO SO, MAY RESULT IN THE INTERRUPTION OR LOSS OF SERVICES, AND
      COMPANY SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY REGARDING THE SAME.
      <br />
      <br />
      If for any reason Company is unable to charge your Payment Method for the
      full amount owed, or if Company receives notification of a chargeback,
      reversal, payment dispute, or is charged a penalty for any fee it
      previously charged to your Payment Method, you agree that Company may
      pursue all available lawful remedies in order to obtain payment, including
      but not limited to, immediate cancellation, without notice to you, of any
      domain names or Services registered or renewed on your behalf. Company
      also reserves the right to charge you reasonable “administrative” fees"
      for (i) tasks Company may perform outside the normal scope of its
      Services, (ii) additional time and/or costs Company may incur in providing
      its Services, and/or (iii) your noncompliance with this Agreement (as
      determined by Company in its sole and absolute discretion). Typical
      administrative or processing fee scenarios include, but are not limited to
      (i) customer service issues that require additional personal time or
      attention; (ii) UDRP actions(s) in connection with your domain name(s)
      and/or disputes that require accounting or legal services, whether
      performed by Company staff or by outside firms retained by Company; (iii)
      recouping any and all costs and fees, including the cost of Services,
      incurred by Company as the results of chargebacks or other payment
      disputes brought by you, your bank or Payment Method processor. These
      administrative fees or processing fees will be billed to the Payment
      Method you have on file with Company.
      <br />
      <br />
      Company may offer product-level pricing in various currencies. The
      transaction will be processed in the selected currency and the pricing
      displayed during the checkout process will be the actual amount submitted
      for payment. For certain Payment Methods, the issuer of your Payment
      Method may charge you a foreign transaction fee or other charge, which may
      be added to the final amount that appears on your bank statement or post
      as a separate amount. Please check with the issuer of your Payment Method
      for details. In addition, regardless of the selected currency, you
      acknowledge and agree that you may be charged Value Added Tax ("VAT"),
      Goods and Services Tax ("GST"), or other localized fees and/or taxes,
      based on your bank and/or the country indicated in your billing address
      section.
      <br />
      <br />
      ‍(B) REFUND POLICY
      <br />
      <br />
      Products and Services available for refunds are described here (“Refund
      Policy”).
    </div>
    <div className="header-small">8. INDEMNIFICATION </div>
    <div className="body-text-legal">
      You agree to indemnify and hold harmless Company and its officers,
      directors, employees, parents, partners, successors, agents, distribution
      partners, affiliates, subsidiaries, and their related companies from and
      against any and all claims, liabilities, losses, damages, obligations,
      costs and expenses (including reasonable attorneys’ fees and costs)
      arising out of, related to, or that may arise in connection with: (i) your
      use of our Services; (ii) User Content provided by you or through use of
      your Membership; (iii) any actual or alleged violation or breach by you of
      these Terms of Use; (iv) any actual or alleged breach of any
      representation, warranty, or covenant that you have made to us; (v) your
      acts or omissions and, if you are a Company User, the acts, omissions,
      breaches of these Terms of Use and/or violation of any applicable law by
      any of your Team Users; and (vi) any activity related to your Membership
      (including infringement of third parties’ worldwide intellectual property
      rights or negligent or wrongful conduct) by you or any other person
      accessing the Website, the Application or the Services using your
      Membership. You agree to cooperate fully with us in the defense of any
      claim that is the subject of your obligations hereunder.
    </div>

    <div className="header-small">
      9. DISCLAIMER OF WARRANTIES; WAIVER AND RELEASE{" "}
    </div>
    <div className="body-text-legal">
      (A) YOU EXPRESSLY AGREE THAT USE OF OUR SERVICES IS AT YOUR SOLE RISK. OUR
      SERVICES AND SERVICE CONTENT (INCLUDING SOFTWARE) ARE PROVIDED ON AN “AS
      IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS
      OR IMPLIED. WITHOUT LIMITING THE FOREGOING AND TO THE FULLEST EXTENT
      PERMITTED BY LAW, COMPANY AND ITS OFFICERS, DIRECTORS, EMPLOYEES, PARENTS,
      PARTNERS, SUCCESSORS, AGENTS, DISTRIBUTION PARTNERS, AFFILIATES,
      SUBSIDIARIES, AND THEIR RELATED COMPANIES DISCLAIM ANY AND ALL WARRANTIES
      INCLUDING ANY: (1) WARRANTIES THAT OUR SERVICES WILL MEET YOUR
      REQUIREMENTS; (2) WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY,
      SECURITY, USEFULNESS, TIMELINESS, OR INFORMATIONAL CONTENT OF OUR SERVICES
      OR SERVICE CONTENT; (3) WARRANTIES OF TITLE, NON-INFRINGEMENT,
      MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE; (4) WARRANTIES FOR
      SERVICES OR GOODS RECEIVED THROUGH OR ADVERTISED OR ACCESSED THROUGH OUR
      SERVICES; (5) WARRANTIES CONCERNING THE ACCURACY OR RELIABILITY OF THE
      RESULTS THAT MAY BE OBTAINED FROM THE USE OF OUR SERVICES; (6) WARRANTIES
      THAT YOUR USE OF OUR SERVICES WILL BE SECURE OR UNINTERRUPTED; AND (7)
      WARRANTIES THAT ERRORS IN OUR SERVICES OR SERVICE CONTENT (INCLUDING
      SOFTWARE) WILL BE CORRECTED. THERE ARE NO WARRANTIES THAT EXTEND BEYOND
      THE FACE OF THESE TERMS. WE CANNOT AND DO NOT WARRANT AGAINST HUMAN AND
      MACHINE ERRORS, OMISSIONS, DELAYS, INTERRUPTIONS OR LOSSES, INCLUDING LOSS
      OF DATA. WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES AVAILABLE
      FOR DOWNLOADING FROM THE WEBSITE OR THE APPLICATION WILL BE FREE OF
      INFECTION BY VIRUSES, WORMS, TROJAN HORSES OR OTHER CODE THAT MANIFEST
      CONTAMINATING OR DESTRUCTIVE PROPERTIES. THIS DISCLAIMER OF WARRANTY
      CONSTITUTES AN ESSENTIAL PART OF THESE TERMS OF USE. IF YOU ARE
      DISSATISFIED WITH ANY PORTION OF THE SERVICE, OR WITH ANY OF THESE TERMS
      OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE
      SERVICE. WE MAY CHANGE THE SERVICE OR THE FEATURES IN ANY WAY, AND AT ANY
      TIME AND FOR ANY REASON. ALTHOUGH WE HAVE ATTEMPTED TO PROVIDE ACCURATE
      INFORMATION ON THE WEBSITE AND APPLICATION, WE ASSUME NO RESPONSIBILITY
      FOR THE ACCURACY OR COMPLETENESS OF THE INFORMATION.
      <br />
      <br />
      (B) YOU AGREE THAT NEITHER THE COMPANY NOR ITS OFFICERS, DIRECTORS,
      EMPLOYEES, AGENTS, LICENSORS OR SUPPLIERS SHALL HAVE ANY LIABILITY TO YOU
      UNDER ANY THEORY OF LIABILITY OR INDEMNITY IN CONNECTION WITH YOUR USE OF
      THE WEBSITE, THE APPLICATION, THE SERVICE, OR THE CONTENT. YOU
      SPECIFICALLY ACKNOWLEDGE THAT THE COMPANY SHALL NOT BE LIABLE FOR THE
      DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY AND THAT THE
      RISK OF HARM OR DAMAGE FROM THE FOREGOING RESTS ENTIRELY WITH YOU. YOU
      HEREBY RELEASE AND FOREVER WAIVE ANY AND ALL CLAIMS YOU MAY HAVE AGAINST
      THE COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, LICENSORS OR
      SUPPLIERS (INCLUDING BUT NOT LIMITED TO CLAIMS BASED UPON THE NEGLIGENCE
      OF THE COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, LICENSORS OR
      SUPPLIERS) FOR LOSSES OR DAMAGES YOU SUSTAIN IN CONNECTION WITH YOUR USE
      OF THE WEBSITE, THE APPLICATION, THE SERVICE, OR THE SERVICE CONTENT.
    </div>

    <div className="header-small">10. LIMITATION ON LIABILITY</div>
    <div className="body-text-legal">
      (A) UNDER NO CIRCUMSTANCES SHALL COMPANY OR ITS OFFICERS, DIRECTORS,
      EMPLOYEES, PARENTS, PARTNERS, SUCCESSORS, AGENTS, DISTRIBUTION PARTNERS,
      AFFILIATES, SUBSIDIARIES, OR THEIR RELATED COMPANIES BE LIABLE FOR
      INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES (EVEN IF
      WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), ARISING OUT OF,
      RELATING TO, OR IN ANY WAY CONNECTED WITH OUR SERVICES OR THESE TERMS OF
      USE. YOUR SOLE REMEDY FOR DISSATISFACTION WITH OUR SERVICES INCLUDING,
      WITHOUT LIMITATION, SERVICE CONTENT IS TO STOP USING OUR SERVICES. SUCH
      LIMITATION SHALL ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY REASON OF
      GOODS RECEIVED THROUGH OR ADVERTISED IN CONNECTION WITH OUR SERVICES OR
      ANY LINKS PLACED IN OUR SERVICES, AS WELL AS BY REASON OF ANY INFORMATION
      OR ADVICE RECEIVED THROUGH OR ADVERTISED IN CONNECTION WITH OUR SERVICES
      OR ANY LINKS PLACED IN OUR SERVICES. SUCH LIMITATION SHALL ALSO APPLY WITH
      RESPECT TO DAMAGES INCURRED BY REASON OF ANY CONTENT POSTED BY A THIRD
      PARTY OR CONDUCT OF A THIRD PARTY USING OUR SERVICES. ‍
      <br />
      <br />
      (B) NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO EVENT
      SHALL THE CUMULATIVE LIABILITY OF COMPANY AND ITS OFFICERS, DIRECTORS,
      EMPLOYEES, PARENTS, PARTNERS, SUCCESSORS, AGENTS, DISTRIBUTION PARTNERS,
      AFFILIATES, SUBSIDIARIES, AND THEIR RELATED COMPANIES EXCEED THE LESSER OF
      THE TOTAL PAYMENTS RECEIVED FROM YOU BY COMPANY DURING THE PRECEDING
      TWELVE (12) MONTH PERIOD OR $100. FURTHERMORE, YOU AGREE THAT ANY CAUSE OF
      ACTION ARISING OUT OF, RELATING TO, OR IN ANY WAY CONNECTED WITH ANY OF
      OUR SERVICES OR THESE TERMS OF USE MUST COMMENCE WITHIN ONE (1) YEAR AFTER
      THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION SHALL BE
      PERMANENTLY BARRED.
      <br />
      <br />
      (C) In some jurisdictions limitations of liability are not permitted. In
      such jurisdictions, some of the foregoing limitations may not apply to
      you. These limitations shall apply to the fullest extent permitted by law.
    </div>

    <div className="header-small">11. TERMINATION</div>
    <div className="body-text-legal">
      (A) We reserve the right in our sole discretion and at any time to
      terminate or suspend your Membership and/or block your use of our Services
      for any reason including, without limitation if you have failed to comply
      with the letter and spirit of these Terms of Use. You agree that Company
      is not liable to you or any third party for any termination or suspension
      of your Membership or for blocking your use of our Services.
      <br />
      <br />
      (B) Any suspension or termination shall not affect your obligations to us
      under these Terms of Use. The provisions of these Terms of Use which by
      their nature should survive the suspension or termination of your
      Membership or these Terms of Use shall survive including, but not limited
      to the rights and licenses that you have granted hereunder, indemnities,
      releases, disclaimers, limitations on liability, provisions related to
      choice of law, and all of the provisions in the Section titled
      “MISCELLANEOUS”.
    </div>

    <div className="header-small">12. COPYRIGHT POLICY</div>
    <div className="body-text-legal">
      (A) We respect the intellectual property rights of others and expect users
      to do the same. In appropriate circumstances and at our sole discretion,
      we may terminate and/or disable the Membership of users suspected to be
      infringing the copyrights (or other intellectual property rights) of
      others. Additionally, in appropriate circumstances and in our sole
      discretion, we may remove or disable access to material on any of our
      websites or hosted on our systems that may be infringing or the subject of
      infringing activity.
      <br />
      <br />
      (B) In accordance with the Digital Millennium Copyright Act of 1998, Title
      17 of the United States Code, Section 512 (“DMCA”), we will respond
      promptly to claims of copyright infringement that are reported to the
      agent that we have designated to receive notifications of claims
      infringement (its “Designated Agent”). Our Designated Agent is: Sticker
      Design Kit, LLC., 950 S 10th St. #58, Omaha, NE 68108 USA. Claims can be
      directed to us at{" "}
      <a href="mailto:hello@stickerdesignkit.com">
        {" "}
        legal@stickerdesignkit.com.
      </a>{" "}
      <br />
      <br />
      (C) If you are a copyright owner (or authorized to act on behalf of the
      copyright owner) and believe that your work’s copyright has been
      infringed, please report your notice of infringement to us by providing
      our Designated Agent with a written notification of claimed infringement
      that includes substantially the following:
      <br />
      <br />
      (i) A physical or electronic signature of a person authorized to act on
      behalf of the owner of an exclusive right that is allegedly infringed.
      (ii) Identification of the copyrighted work claimed to have been
      infringed, or, if multiple copyrighted works at a single online site are
      covered by a single notification, a representative list of such works at
      that site. (iii) Identification of the material that is claimed to be
      infringing or to be the subject of infringing activity and that is to be
      removed or access to which is to be disabled, and information reasonably
      sufficient to permit us to locate the material. (iv) Information
      reasonably sufficient to permit us to contact you, such as an address,
      telephone number, and, if available, an electronic mail address at which
      you may be contacted. (v) A statement that you have a good faith belief
      that use of the material in the manner complained of is not authorized by
      the copyright owner, its agent, or the law. (vi) A statement that the
      information in the notification is accurate, and under penalty of perjury,
      that you are authorized to act on behalf of the owner of an exclusive
      right that is allegedly infringed.
      <br />
      <br />
      We will investigate notices of copyright infringement and take appropriate
      actions under the DMCA. Inquiries that do not follow this procedure may
      not receive a response.
    </div>

    <div className="header-small">
      13. CHOICE OF LAW; JURISDICTION AND VENUE
    </div>
    <div className="body-text-legal">
      These Terms of Use shall be construed in accordance with the laws of the
      State of Nebraska without regard to its conflict of laws rules. Any legal
      proceedings against Company that may arise out of, relate to, or be in any
      way connected with our Website, Application, Services or these Terms of
      Use shall be brought exclusively in the state and federal courts of the
      State of Nebraska and you waive any jurisdictional, venue, or inconvenient
      forum objections to such courts.
    </div>

    <div className="header-small">
      14. DISPUTE RESOLUTION & MANDATORY ARBITRATION
    </div>
    <div className="body-text-legal">
      (A) We each agree to first contact each other with any disputes and
      provide a written description of the problem, all relevant
      documents/information and the proposed resolution. You agree to contact us
      with disputes by contacting us at the address provided in these Terms of
      Use. We will contact you based on the contact information you have
      provided us.
      <br />
      <br />
      (B) If after 30 days the parties are unable to resolve any dispute raised
      under the previous provision, the dispute may be submitted to arbitration
      consistent with this Section. The parties understand that they would have
      had a right or opportunity to litigate disputes through a court and to
      have a judge or jury decide their case, but they choose to have any
      disputes resolved through arbitration.
      <br />
      <br />
      (C) We each agree that any claim or dispute between us, and any claim by
      either of us against any agent, employee, successor, or assign of the
      other, including, to the full extent permitted by applicable law, third
      parties who are not signatories to this agreement, whether related to this
      agreement or otherwise, including past, present, and future claims and
      disputes, and including any dispute as to the validity or applicability of
      this arbitration clause, shall be resolved by binding arbitration
      administered by the JAMS under its rules and procedures in effect when the
      claim is filed. The rules and procedures and other information, including
      information on fees, may be obtained from JAMS’ website
      <span className="bold">
        <a href="https://jamsadr.com"> ( www.jamsadr.com ) </a>
      </span>
      or by calling JAMS at 949-224-1810.
      <br />
      <br />
      (D) We are entering into this arbitration agreement in connection with a
      transaction involving interstate commerce. Accordingly, this arbitration
      agreement and any proceedings thereunder shall be governed by the Federal
      Arbitration Act (“FAA”), 9 U.S.C. §§ 1-16. Any award by the arbitrator(s)
      may be entered as a judgment in any court having jurisdiction.
      <br />
      <br />
      (E) Exception to Arbitrate. Either of us may bring qualifying claims in
      small claims court. Further, as set forth below, we each agree that any
      arbitration will be solely between you and Company, not as part of a class
      wide claim (i.e., not brought on behalf of or together with another
      individual's claim). If for any reason any court or arbitrator holds that
      this restriction is unconscionable or unenforceable, then our agreement to
      arbitrate doesn't apply and the class wide dispute must be brought in
      court.
    </div>

    <div className="header-small">15. NO CLASS ACTIONS</div>
    <div className="body-text-legal">
      TO THE EXTENT ALLOWED BY LAW, WE EACH WAIVE ANY RIGHT TO PURSUE DISPUTES
      ON A CLASS WIDE BASIS; THAT IS, TO EITHER JOIN A CLAIM WITH THE CLAIM OF
      ANY OTHER PERSON OR ENTITY, OR ASSERT A CLAIM IN A REPRESENTATIVE CAPACITY
      ON BEHALF OF ANYONE ELSE IN ANY LAWSUIT, ARBITRATION OR OTHER PROCEEDING.
    </div>

    <div className="header-small">16. NO TRIAL BY JURY</div>
    <div className="body-text-legal">
      TO THE EXTENT ALLOWED BY LAW, WE EACH WAIVE ANY RIGHT TO TRIAL BY JURY IN
      ANY LAWSUIT, ARBITRATION OR OTHER PROCEEDING.
    </div>

    <div className="header-small">17. AMENDMENT; ADDITIONAL TERMS</div>
    <div className="body-text-legal">
      (A) We reserve the right in our sole discretion and at any time and for
      any reason, to modify or discontinue any aspect or feature of our Services
      or to modify these Terms of Use. In addition, we reserve the right to
      provide you with operating rules or additional terms that may govern your
      use of our Services generally, unique of our Services, or both
      (“Additional Terms”). Any Additional Terms that we may provide to you will
      be incorporated by reference into these Terms of Use. To the extent any
      Additional Terms conflict with these Terms of Use, the Additional Terms
      will control.
      <br />
      <br />
      (B) Modifications to these Terms of Use or Additional Terms will be
      effective immediately upon notice, either by posting on the Website,
      notification by email or through any of our Applications. It is your
      responsibility to review the Terms of Use from time to time for any
      changes or Additional Terms. Your access and use of our Services following
      any modification of these Terms of Use or the provision of Additional
      Terms will signify your assent to and acceptance of the same. If you
      object to any subsequent revision to the Terms of Use or to any Additional
      Terms, immediately discontinue use of our Services and, if applicable,
      terminate your Membership.
    </div>

    <div className="header-small">18. MISCELLANEOUS</div>
    <div className="body-text-legal">
      (A) No waiver by either party of any breach or default hereunder shall be
      deemed to be a waiver of any preceding or subsequent breach or default. No
      waiver shall be binding unless executed in writing by the party making the
      waiver. The section headings used herein are for convenience only and
      shall not be given any legal import.
      <br />
      <br />
      (B) Except where specifically stated otherwise, if any part of these Terms
      of Use is unlawful or unenforceable for any reason, we both agree that
      only that part of the Terms of Use shall be stricken and that the
      remaining terms in the Terms of Use shall not be affected.
      <br />
      <br />
      (C) These Terms of Use (including the Privacy Policy and any Additional
      Terms incorporated by reference) constitute the entire agreement of the
      parties with respect to the subject matter hereof, and supersede all
      previous written or oral agreements between us with respect to such
      subject matter.
      <br />
      <br />
      (D) You may not assign these Terms of Use or assign any rights or delegate
      any obligations hereunder, in whole or in part, without our prior written
      consent. Any such purported assignment or delegation by you without the
      appropriate prior written consent will be null and void and of no force
      and effect. We may assign these Terms of Use or any rights hereunder
      without your consent and without notice.
      <br />
      <br />
      (E) No provisions of these Terms of Use are intended, nor will be
      interpreted, to provide or create any third party beneficiary rights or
      any other rights of any kind in any nonprofit user, client, customer,
      affiliate, or any party hereto or any other person unless specifically
      provided otherwise herein, and except as so provided, all provisions
      hereof will be personal solely between the parties to these Terms of Use;
      except that Sections 7, 8(b), and 9 are intended to benefit the Company
      and its officers, directors, employees, agents, licensors, and suppliers.
      <br />
      <br />
      (F) We may deliver notice to you under these Terms of Use by means of
      electronic mail, a general notice on the Website or the Application, or by
      written communication delivered by first class U.S. mail to your address
      on record in the Membership. You may give notice to us at any time via
      electronic mail to the Website at the following address:{" "}
      <a href="mailto:legal@stickerdesignkit.com">
        {" "}
        legal@stickerdesignkit.com.
      </a>{" "}
    </div>
    <br />
    <br />

    <div className="header-big" id="privacypolicy">
      Privacy Policy
    </div>
    <div className="header-date">Effective April 12, 2021 </div>
    <br />
    <div className="body-text-legal">
      Sticker Design Kit LLC cares about your privacy. For this reason, we
      collect and use personal information only as needed to deliver our
      products, services, websites and mobile applications, and to communicate
      with you about the same, or as you have requested (collectively, our
      “Services”). Your personal information includes information such as:
      <br />
      <br />
      <ul>
        <li>Name</li>
        <li>Address</li>
        <li>Telephone Number</li>
        <li>Date of Birth</li>
        <li>Billing and Payment Information</li>
        <li>Candidate information (for job applicants)</li>
        <li>
          Other data collected that could directly or indirectly identify you.
        </li>
      </ul>
      <br />
      Our Privacy Policy not only explains how and why we use your personal
      information that we collect, but also how you can access, update or
      otherwise take control of your personal information.
      <br />
      <br />
      If at any time you have questions about our practices or any of your
      rights described below, you may reach our Data Protection Officer (“DPO”)
      and our dedicated team that supports this office by contacting us at
      <a href="mailto:privacy@stickerdesignkit.com">
        {" "}
        privacy@stickerdesignkit.com.
      </a>{" "}
      This inbox is actively monitored and managed so that we can deliver an
      experience that you can confidently trust.
      <br />
      <br />
      What information we collect, how we collect it, and why
      <br />
      <br />
      Much of what you likely consider personal information is collected
      directly from you when you:
      <br />
      <br />
      <ul>
        <li>
          {" "}
          create an account or purchase any of our Services (ex: billing
          information, including name, address, credit card number);
        </li>{" "}
        <li>
          request assistance from our award-winning customer support team (ex:
          phone number); complete contact forms or request newsletters or other
          information from us (ex: email); or{" "}
        </li>
        <li>
          participate in contests and surveys, apply for a job, or otherwise
          participate in activities we promote that might require information
          about you.
        </li>
      </ul>
      <br />
      <br />
      However, we also collect additional information when delivering our
      Services to you to ensure necessary and optimal performance. These methods
      of collection may not be as obvious to you, so we thought we’d highlight
      and explain a bit more about what these might be (as they vary from time
      to time):
      <br />
      <br />
      <span className="bold">Cookies and similar technologies</span> on our
      websites and our mobile applications allow us to track your browsing
      behavior, links clicked, items purchased, your device type, and to collect
      various data, including analytics, about how you use and interact with our
      Services. These technologies automatically collect data when you use and
      interact with our Services, including metadata, log files, cookie/device
      IDs, page load time, server response time, and approximate location
      information to measure website performance and improve our systems,
      including optimizing DNS resolution, network routing and server
      configurations. Specifically, interactions with the features, content and
      links (including those of third-parties, such as social media plugins)
      contained within the Services, Internet Protocol (IP) address, browser
      type and settings, the date and time the Services were used, information
      about browser configuration and plugins, language preferences and cookie
      data, information about devices accessing the Services, including type of
      device, what operating system is used, device settings, application IDs,
      unique device identifiers and error data is collected. All this allows us
      to provide you with more relevant product offerings, a better experience
      on our sites and mobile applications, and to collect, analyze and improve
      the performance of our Services. We may also collect your location (IP
      address) so that we can personalize our Services. For additional
      information, and to learn how to manage the technologies we utilize,
      please visit our <span className="bold">Cookie Policy.</span> If you wish
      to opt out of interest-based advertising click{" "}
      <span className="bold">here</span> [or if located in the European Union
      click <span className="bold">here</span>]. Please note you will continue
      to receive generic ads.
      <br />
      <br />
      <span className="bold">Supplemented Data</span> may be received about you
      from other sources, including publicly available databases or third
      parties from whom we have purchased data, in which case we may combine
      this data with information we already have about you so that we can
      update, expand and analyze the accuracy of our records, assess the
      qualifications of a candidate for employment, identify new customers, and
      provide products and services that may be of interest to you. If you
      provide us personal information about others, or if others give us your
      information, we will only use that information for the specific reason for
      which it was provided to us.
      <br />
      <br />
      <span className="bold">How we utilize information.</span>
      <br />
      <br />
      We strongly believe in both minimizing the data we collect and limiting
      its use and purpose to only that (1) for which we have been given
      permission, (2) as necessary to deliver the Services you purchase or
      interact with, or (3) as we might be required or permitted for legal
      compliance or other lawful purposes:
      <br />
      <br />
      <span className="bold">
        Delivering, improving, updating and enhancing our Services.
      </span>
      We collect various information relating to your purchase, use and/or
      interactions with our Services. We utilize this information to:
      <br />
      <br />
      <li>
        Improve and optimize the operation and performance of our Services
        (again, including our websites and mobile applications)
      </li>{" "}
      <li>
        Diagnose problems with and identify any security risks, errors, or
        needed enhancements to the Services{" "}
      </li>{" "}
      <li>Detect and prevent fraud and abuse of our Services and systems </li>{" "}
      <li>Collecting aggregate statistics about use of the Services </li>
      <li>
        {" "}
        Understand and analyze how you use our Services and what products and
        services are most relevant to you.
      </li>
      <br />
      <br />
      Much of the data collected is aggregated or statistical data about how
      individuals use our Services, and is not linked to any personal
      information.
      <br />
      <br />
      <span className="bold">Sharing with trusted third parties. </span>
      We may share your personal information with third parties that we have
      partnered to allow you to integrate their services into our own Services,
      and with our affiliates or trusted third party service providers as
      necessary for them to perform services on our behalf, such as:
      <br />
      <br />
      <ul>
        <li>Processing credit card payments</li>
        <li>Serving advertisements</li>
        <li> Conducting contests or surveys</li>
        <li> Performing analysis of our Services and customers demographics</li>
        <li>
          Communicating with you, such as by way email or survey delivery{" "}
        </li>
        <li>Customer relationship management </li>
        <li>Recruiting support and related services </li>
        <li> Others providing services on our behalf.</li>
      </ul>
      <br />
      <br />
      These third parties (and any subcontractors they may be permitted to use)
      have agreed not to share, use or retain your personal information for any
      purpose other than as necessary for the provision of Services.
      <br />
      <br />
      We will also disclose your information to our affiliates or third parties:
      <ul>
        <li>
          In the event that we sell or buy any business or assets (whether a
          result of liquidation, bankruptcy or otherwise), in which case we will
          disclose your data to the prospective seller or buyer of such business
          or assets; or{" "}
        </li>
        <li>
          If we sell, buy, merge, are acquired by, or partner with other
          companies or businesses, or sell some or all of our assets. In such
          transactions, your information may be among the transferred assets.
        </li>
      </ul>
      <br />
      <br />
      <span className="bold">Communicating with you. </span>
      We may contact you directly or through a third party service provider
      regarding products or services you have signed up or purchased from us,
      such as necessary to deliver transactional or service related
      communications. We may also contact you with offers for additional
      services we think you’ll find valuable if you give us consent, or where
      allowed based upon legitimate interests. You don’t need to provide consent
      as a condition to purchase our goods or services. These contacts may
      include:
      <br />
      <br />
      <ul>
        <li>Email</li>
        <li>Text (SMS) messages</li>
        <li>Telephone Calls</li>
        <li>Messenger applications (e.g. WhatsApp, etc.)</li>
        <li>Automated phone calls or text messages.</li>
      </ul>
      <br />
      <br />
      You may also update your subscription preferences with respect to
      receiving communications from us and/or our partners by signing into your
      account and visiting “Account Settings” page.
      <br />
      <br />
      If we collect information from you in connection with a co-branded offer,
      it will be clear at the point of collection who is collecting the
      information and whose privacy policy applies. In addition, it will
      describe any choice options you have in regards to the use and/or sharing
      of your personal information with a co-branded partner, as well as how to
      exercise those options. We are not responsible for the privacy practices
      or the content of third-party sites. Please read the privacy policy of any
      website you visit.
      <br />
      <br />
      If you make use of a service that allows you to import contacts (ex. using
      email marketing services to send emails on your behalf), we will only use
      the contacts and any other personal information for the requested service.
      If you believe that anyone has provided us with your personal information
      and you would like to request that it be removed from our database, please
      contact us at{" "}
      <a href="mailto:hello@stickerdesignkit.com">
        {" "}
        hello@stickerdesignkit.com.
      </a>{" "}
      <br />
      <br />
      <span className="bold">Transfer of personal information abroad. </span>
      Transfer of personal information abroad. If you utilize our Services from
      a country other than the country where our servers are located, your
      personal information may be transferred across international borders.
      Also, when you call us or initiate a chat, we may provide you with support
      from one of our global locations outside your country of origin.
      <br />
      <br />
      <span className="bold">
        Compliance with legal, regulatory and law enforcement requests.{" "}
      </span>
      We cooperate with government and law enforcement officials and private
      parties to enforce and comply with the law. We will disclose any
      information about you to government or law enforcement officials or
      private parties as we, in our sole discretion, believe necessary or
      appropriate to respond to claims and legal process (such as subpoena
      requests), to protect our property and rights or the property and rights
      of a third party, to protect the safety of the public or any person, or to
      prevent or stop activity we consider to be illegal or unethical.
      <br />
      <br />
      To the extent we are legally permitted to do so, we will take reasonable
      steps to notify you in the event that we are required to provide your
      personal information to third parties as part of legal process. We will
      also share your information to the extent necessary to comply with any
      ICANN, registry or ccTLD rules, regulations and policies when you register
      a domain name with us. For reasons critical to maintaining the security,
      stability and resiliency of the Internet, this includes the transfer of
      domain name registration information to the underlying domain registry
      operator and escrow provider, and publication of that information as
      required by ICANN in the public WHOIS database or with other third parties
      that demonstrate a legitimate legal interest to such information.
      <br />
      <br />
      <span className="bold">How we secure, store and retain your data.</span>
      <br />
      <br />
      We follow generally accepted standards to store and protect the personal
      information we collect, both during transmission and once received and
      stored, including utilization of encryption where appropriate.
      <br />
      <br />
      We retain personal information only for as long as necessary to provide
      the Services you have requested and thereafter for a variety of legitimate
      legal or business purposes.
      <br />
      <br />
      These might include retention periods:
      <ul>
        <li>
          mandated by law, contract or similar obligations applicable to our
          business operations;
        </li>
        <li>
          for preserving, resolving, defending or enforcing our
          legal/contractual rights; or{" "}
        </li>
        <li>
          Needed to maintain adequate and accurate business and financial
          records.
        </li>
      </ul>
      <br />
      <br />
      If you have any questions about the security or retention of your personal
      information, you can contact us at
      <a href="mailto:privacy@stickerdesignkit.com">
        {" "}
        privacy@stickerdesignkit.com.
      </a>{" "}
      <br />
      <br />
      <span className="bold">
        {" "}
        How you can access, update or delete your data.
      </span>
      <br />
      <br />
      To easily access, view, or update your personal information, or to update
      your subscription preferences, please sign into your Account and visit
      “Account Settings.”
      <br />
      <br />
      If you wish to delete or port your personal information, you may submit
      your request to{" "}
      <a href="mailto:privacy@stickerdesignkit.com">
        {" "}
        privacy@stickerdesignkit.com.
      </a>{" "}
      If you make a request to delete your personal information and that data is
      necessary for the products or services you have purchased, the request
      will be honored only to the extent it is no longer necessary for any
      Services purchased or required for our legitimate business purposes or
      legal or contractual record keeping requirements.
      <br />
      <br />
      If you are unable for any reason to access your Account Settings, you may
      also contact us by one of the methods described in the “Contact Us”
      section below.
      <br />
      <br />
      <span className="bold"> ‘Do Not Track’ notifications.</span>
      <br />
      <br />
      Some browsers allow you to automatically notify websites you visit not to
      track you using a “Do Not Track” signal. There is no consensus among
      industry participants as to what “Do Not Track” means in this context.
      Like many websites and online services, we currently do not alter our
      practices when we receive a “Do Not Track” signal from a visitor’s
      browser. To find out more about “Do Not Track,” you may wish to visit
      <span className="bold">
        <a href="https://allaboutdnt.com"> (www.allaboutdnt.com) </a>
      </span>
      <br />
      <br />
      <span className="bold"> Age restrictions.</span>
      <br />
      <br />
      Our Services are available for purchase only for those over the age of 18.
      Our Services are not targeted to, intended to be consumed by or designed
      to entice individuals under the age of 18. If you know of or have reason
      to believe anyone under the age of 18 has provided us with any personal
      information, please contact us.
      <br />
      <br />
      <span className="bold"> Non-Discrimination.</span>
      <br />
      <br />
      We will not discriminate against you for exercising any of your privacy
      rights. Unless permitted under applicable laws, we will not:
      <br />
      <br />
      <ul>
        <li>Deny you goods or services.</li>
        <li>
          Charge you different prices or rates for goods or services, including
          through granting discounts or other benefits, or imposing penalties.
        </li>
        <li>Provide you a different level or quality of goods or services.</li>
        <li>
          {" "}
          Suggest that you may receive a different price or rate for goods or
          services or a different level or quality of goods or services.
        </li>
      </ul>
      <br />
      <br />
      <span className="bold"> Changes to this policy.</span>
      <br />
      <br /> We reserve the right to modify this Privacy Policy at any time. If
      we decide to change our Privacy Policy, we will post those changes to this
      Privacy Policy and any other places we deem appropriate, so that you are
      aware of what information we collect, how we use it, and under what
      circumstances, if any, we disclose it. If we make material changes to this
      Privacy Policy, we will notify you here, by email, or by means of a notice
      on our home page, at least thirty (30) days prior to the implementation of
      the changes.
      <br />
      <br />
      <span className="bold"> Data Protection Authority.</span>
      <br />
      <br />
      If you are a resident of the European Economic Area (EEA) and believe we
      maintain your personal information subject to the General Data Protection
      Regulation (GDPR), you may direct questions or complaints to your local
      supervisory authority or our lead supervisory authority, the UK's
      Information Commissioner’s Office, as noted below:
      <br />
      <br />
      <span className="bold">
        <a href="https://ico.org.uk"> www.ico.org.uk</a>
      </span>
      <br />
      <br />
      Information Commissioner’s Office
      <br />
      <br />
      Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF, United Kingdom
      <br />
      <br />
      Phone: 0303 123 1113
      <br />
      <br />
      <span className="bold"> Contact us.</span>
      <br />
      <br />
      If you have any privacy-related questions, concerns or complaints about
      our Privacy Policy, our practices or our Services, you may contact our
      Office of the DPO by email at{" "}
      <a href="mailto:privacy@stickerdesignkit.com">
        {" "}
        privacy@stickerdesignkit.com.
      </a>{" "}
      In the alternative, you may contact us by either of the following means:
      <br />
      <br />
      By Mail: Attn: Office of the Data Protection Officer, 950 S 10th St. #58,
      Omaha, NE 68108.
      <br />
      <br />
      <span className="bold">
        We will respond to all requests, inquiries or concerns within thirty
        (30) days.{" "}
      </span>
    </div>
    <br />
  </div>
);

export default Terms;
