import firebase from "../firebase";

const db = firebase.collection("/newsletter");

const create = (data) => {
  return db.add(data);
};

const NewsletterService = {
  create,
};

export default NewsletterService;
