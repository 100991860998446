import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import GlobalStyles from "./GlobalStyles";
import "./App.css";
import About from "./components/about/About";
import Home from "./components/home/Home";
import Flair from "./components/flair/Flair";
import Terms from "./components/legal/terms";
import Privacy from "./components/legal/privacy";
import Feature from "./components/shared/Feature";

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <GlobalStyles />
        <Route exact path="/" component={Home} />
        <Route path="/flair" component={Flair} />
        <Route path="/about" component={About} />
        <Route path="/terms" component={Terms} />
        <Route path="/privacy" component={Privacy} />
        <Route
          path="/for-you"
          render={() => (
            <Feature
              imgSrc="neato"
              altText=""
              title="Specially Picked For You"
              body="We combine your tastes and interests to offer unique collections of materials and fonts we think you’ll love!"
            ></Feature>
          )}
        />
        <Route
          path="/sticker-packs"
          render={() => (
            <Feature imgSrc="neato" altText="" title="" body=""></Feature>
          )}
        />
        <Route
          path="/content-library"
          render={() => (
            <Feature imgSrc="neato" altText="" title="" body=""></Feature>
          )}
        />
        <Route
          path="/controls"
          render={() => (
            <Feature imgSrc="neato" altText="" title="" body=""></Feature>
          )}
        />
        <Route
          path="/saved-materials"
          render={() => (
            <Feature imgSrc="neato" altText="" title="" body=""></Feature>
          )}
        />
        <Route
          path="/social-sharing"
          render={() => (
            <Feature imgSrc="neato" altText="" title="" body=""></Feature>
          )}
        />
      </BrowserRouter>
    </div>
  );
};
export default App;
