const About = ({ quickTest }) => {
  console.log("qhat", quickTest);
  return (
    <div>
      <div className="header-big">
        Discover, Create, <br />& Communicate
      </div>
      <div>this is a {quickTest}</div>
      <div className="body-text">
        What started as an exploration into creating custom stickers using iOS
        keyboard extensions in 2017 has evolved into a huge curated library of
        fonts, textures, and other creative elements.
      </div>
      <br />
      <div className="body-text">
        Sticker Design Kit is an aptly titled software as a service on a mission
        to make it simple to create, organize, and share your stickers -
        Anywhere!
      </div>
      <br />
      <div className="body-text">
        Flair provides access to the SDK service as well as over 14 intuitive
        graphic design tools and seemless sharing to Snapchat, Facebook
        Messenger, WhatsApp, and other messaging apps.
      </div>
    </div>
  );
};

export default About;
