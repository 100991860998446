const Privacy = () => (
  <div>
    <div className="header-big">
      Our use of cookies, web beacons, and similar technologies
    </div>
    <div className="header-date">Effective April 12, 2021 </div>
    <br />
    <div className="body-text-legal">
      First and foremost,{" "}
      <span className="bold">we DO NOT sell your personal information.</span>{" "}
      However, when you visit or interact with our sites, services,
      applications, tools or messaging, we or our authorized service providers
      may use cookies, web beacons, and other similar technologies to make your
      experience better, faster and safer, for advertising purposes and to allow
      us to continuously improve our sites, services, applications and tools.
      Any personal information or data collected by these technologies is used
      only by us or by our authorized service providers on our behalf.
      <br />
      <br />
      <div className="bold">
        What are cookies, web beacons, and similar technologies
      </div>
      <br />
      <br />
      These technologies are essentially small data files placed on your
      computer, tablet, mobile phone or other device (“collectively, a “device”)
      that allows us to record information when you visit or interact with our
      websites, service, applications, messaging, and other tools. Though often
      these technologies are generically referred to as “Cookies,” each
      functions slightly differently, and is better explained below:
      <br />
      <br />
      <span className="bold">Cookies:</span> Cookies are small (often encrypted)
      text files placed in the memory of your web browser or device when you
      visit a website or view a message. Cookies allow a website to recognize a
      particular device or browser. There are several types of cookies:
      <br />
      <br />
      <ul>
        <li>
          Session cookies expire at the end of your browser session and allow us
          to link your actions during that particular browser session.{" "}
        </li>
        <li>
          Persistent cookies are stored on your device in between browser
          sessions, allowing us to remember your preferences or actions across
          multiple sites.
        </li>
        <li>
          {" "}
          First-party cookies are those set by a website that is being visited
          by the user at the time in order to preserve your settings (e.g.,
          while on our site).
        </li>
        <li>
          Third-party cookies are placed in your browser by a website, or
          domain, that is not the website or domain that you are currently
          visiting. If a user visits a website and another entity sets a cookie
          through that website this would be a third-party cookie.{" "}
        </li>
      </ul>
      Cookies are used for a variety of purposes on our website. For example,
      they store information that allows us to present our site in your local
      language, or prices in local currency. They also communicate data to our
      servers when you visit our site, allowing you to stay logged in to your
      account during your visit or maintain the site preferences you set.
      Preventing your browser from accepting 1st party cookies will prevent the
      placement of some cookies that are classified as “Essential”.
      <br></br>
      <br></br>
      <span className="bold">Web beacons (pixels):</span> A web beacon (also
      called “tracking pixels” or “image tags”) is a small file (most often a
      transparent, 1x1 GIF file) that loaded on our web pages. These pixels may
      work in concert with cookies to collect information about your visit, your
      web browser/device, browsing activity, or onsite behavior and provide that
      information to service providers. Pixels are most commonly used to collect
      anonymous traffic metrics (page visits, button clicks, order completion)
      used to analyze site performance.
      <br></br>
      <br></br>
      <span className="bold">Scripts:</span> A script is a small piece of
      website code placed on our websites to power customer service tools like
      live chat, allow for the delivery of video tutorials in our help section,
      and allow us to provide interactive experiences to visitors. They are also
      used to collect data that we use for website analytics, or to provide data
      on the effectiveness of our advertising.
      <br></br>
      <br></br>
      <span className="bold">Similar technologies:</span>
      Technologies that store information in your browser or device utilizing
      local shared objects or local storage, such as HTML 5 cookies, and other
      web application software methods. These technologies can operate across
      all of your browsers. In some instances, these technologies may not be
      fully managed by your browser and may require management directly through
      your installed applications or device. We do not use these technologies
      for storing information to target advertising to you on or off our sites.
      <br />
      <br />
      <div className="bold">What technologies do we use and why </div>
      <br />
      <br />
      Our cookies, web beacons and similar technologies serve various purposes,
      but generally they (1) are necessary or essential to the functioning of
      our sites, services, applications, tools or messaging, (2) help us improve
      the performance of or provide you extra functionality of the same, (3)
      help us to serve relevant and targeted advertisements, or (4) allow us to
      offer support tools that you utilize to interact with our care guides:
      <br></br> <br></br>
      <span className="bold">Strictly Necessary or Essential:</span> "Strictly
      necessary" or “essential” cookies, web beacons and similar technologies
      let you move around the website and use essential features like secure
      areas and shopping baskets. Without these technologies, services you have
      asked for cannot be provided. Please note that these technologies do not
      gather any information about you that could be used for marketing or
      remembering where you've been on the internet. Accepting these
      technologies is a condition of using our sites, services, applications,
      tools or messaging, so if you utilize tools that might prevent these from
      loading, we can't guarantee your use or how the security therein will
      perform during your visit.
      <br></br>
      <br></br>
      <span className="bold">Performance:</span> Performance technologies may
      include first or third-party cookies, web beacons/pixels, and scripts
      placed in order to gather information about how you use our website (pages
      you visit, if you experience any errors, load times). These cookies do not
      collect any information that could identify you and are only used to help
      us improve how our website works, understand the interests of our users,
      and measure how effective our content is by providing anonymous statistics
      and data regarding how our website is used.
      <br></br>
      <br></br>
      <span className="bold">Advertising:</span> Advertising technology may
      include first or third-party cookies, web beacons/pixels, and scripts
      placed in order to gather information on the effectiveness of our
      marketing efforts, deliver personalized content, or to generate data that
      allows for the delivery of advertising relevant to your specific interests
      on our sites, as well as third-party websites. We also utilize 3rd party
      service providers to assist us in delivering on the same functions, which
      means that our authorized service providers may also place cookies, web
      beacons and similar technologies on your device via our services (first
      and third party cookies). They may also collect information that helps
      them identify your device, such as IP address, or other unique or device
      identifiers.
      <br></br>
      <br></br>
      <span className="bold">Support:</span> Support technologies may include
      first or third-party cookies, web beacons / pixels, and scripts placed in
      order to provide tools for you to interact with our customer support
      teams. This technology allows us to provide chat services, gather customer
      feedback, and other tools used to support our visitors. Data collected for
      these purposes is never used for marketing or advertising purposes.
      <br />
      <br />
      Cookies and similar technologies on our websites and our mobile
      applications allow us to track your browsing behavior, links clicked,
      items purchased, your device type, and to collect various data, including
      analytics, about how you use and interact with our Services. These
      technologies automatically collect data when you use and interact with our
      Services, including metadata, log files, cookie/device IDs, page load
      time, server response time, and approximate location information to
      measure website performance and improve our systems, including optimizing
      DNS resolution, network routing and server configurations. Specifically,
      interactions with the features, content and links (including those of
      third-parties, such as social media plugins) contained within the
      Services, Internet Protocol (IP) address, browser type and settings, the
      date and time the Services were used, information about browser
      configuration and plugins, language preferences and cookie data,
      information about devices accessing the Services, including type of
      device, what operating system is used, device settings, application IDs,
      unique device identifiers and error data is collected. All this allows us
      to provide you with more relevant product offerings, a better experience
      on our sites and mobile applications, and to collect, analyze and improve
      the performance of our Services. We may also collect your location (IP
      address) so that we can personalize our Services. For additional
      information, and to learn how to manage the technologies we utilize,
      please visit our Cookie Policy. If you wish to opt out of interest-based
      advertising click here [or if located in the European Union click here].
      Please note you will continue to receive generic ads.
      <br />
      <br />
      <div className="bold">
        {" "}
        How to manage, control and delete these technologies
      </div>
      <br />
      We strongly believe in both minimizing the data we collect and limiting
      its use and purpose to only that (1) for which we have been given
      permission, (2) as necessary to deliver the Services you purchase or
      interact with, or (3) as we might be required or permitted for legal
      compliance or other lawful purposes:
      <br />
      <br />
      <div className="bold"> Contact Us </div>
      <br />
      <br />
      We hope the information in this policy provides you with clear information
      about the technologies we use and the purposes for which we use them, but
      it you have any additional questions, or require any additional
      information, please review our Privacy Policy, or contact us at
      <a href="mailto:privacy@stickerdesignkit.com">
        {" "}
        privacy@stickerdesignkit.com.
      </a>{" "}
      <br />
      <br />
    </div>
    <br />
  </div>
);

export default Privacy;
