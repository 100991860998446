import React, { useState } from "react";
import NewsletterService from "../../services/newsletter.service";

const EmailInput = () => {
  // basic email regEx
  const emailRegex = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;

  // define initial values for 'form' input object
  // update form.email, etc using setState
  const [form, setState] = useState({
    email: "",
    isValid: false,
    published: false,
  });

  // use this one to handle the styling states
  // const [valid, setValid] = useState(false);

  // currently prints a valid email
  const verifyEmail = (e) => {
    e.preventDefault();

    const isEmailValid = emailRegex.test(form.email);

    setState({
      ...form,
      isValid: isEmailValid,
    });

    if (isEmailValid) {
      saveEmail();
    }
  };

  const updateField = (e) => {
    setState({
      ...form,
      email: e.target.value,
    });
  };

  const saveEmail = () => {
    var data = {
      value: form.email,
    };

    NewsletterService.create(data)
      .then(() => {
        console.log("******** newsletter service did create ** ");
        // setSubmitted(true);
      })
      .catch((e) => {
        console.log("******** bummer ** ", e.target);
      });
  };

  return (
    <form className="flex-me" onSubmit={verifyEmail}>
      <input
        className="flair-input"
        name="email"
        type="email"
        placeholder="Enter your email"
        value={form.email}
        onChange={updateField}
      />

      <button className="flair-button" type="submit">
        Notify Me
      </button>
    </form>
  );
};

export default EmailInput;
