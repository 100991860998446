import React from "react";
import ReactDOM from "react-dom";
import "./forms.css";

class NameForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    alert("A name was submitted: " + this.state.value);
    event.preventDefault();
  }

  render() {
    return (
      <form id="inputBox" onSubmit={this.handleSubmit}>
        <input
          placeholder=" Your E-Mail"
          id="inputForm"
          type="text"
          value={this.state.value}
          onChange={this.handleChange}
        />
        <button id="notify" type="submit">
          Notify Me
        </button>
      </form>
    );
  }
}

ReactDOM.render(<NameForm />, document.getElementById("root"));
export default NameForm;
