import NameForm from "../shared/forms";
import LibraryBullet from "../../assets/images/library.svg";
import ShareBullet from "../../assets/images/share.svg";
import ToolBullet from "../../assets/images/tools.svg";

import SDKFooter from "../shared/Footer";

import "./home.css";
import Header from "../shared/Header";
import IconBullet from "../IconBullet";

import laughing from "../../assets/images/laughing.png";
import flairSticker from "../../assets/images/flairSticker.png";
import sayLess from "../../assets/images/sayLess.png";

// const CardText = () => <div>test </div>;
const styles = { fontWeight: "bold" };
const Home = () => (
  <div>
    <Header></Header>
    <div className="card-landing">
      <div className="container-box">
        <div className="card-text"> Hey there! </div>
        <div className="card-text flex-me bold">
          Do you&nbsp;
          <div style={{ textDecorationLine: "line-through" }}>
            &nbsp;like&nbsp;
          </div>
          <div style={styles}>&nbsp;LOVE&nbsp;</div> stickers too?!
        </div>
        <div className="card-text">
          <a href="/flair">Flair</a>, makes it easy to create, organize, and
          share your own custom stickers!
        </div>
        {/* tools bullet */}
        <IconBullet
          iconImage={LibraryBullet}
          iconText=" Intuitive Graphic Design Tools "
        ></IconBullet>
        <IconBullet
          iconImage={ToolBullet}
          iconText=" 3000+ Curated Creative Assets "
        ></IconBullet>
        <IconBullet
          iconImage={ShareBullet}
          iconText=" Share Anywhere "
        ></IconBullet>

        <div className="card-text">
          Enter your email here, and we’ll let you know as soon as Flair is
          ready!
        </div>
        <br />
        <NameForm />

        <div className="card-text">♥️ Your Sticker Design Kit Team </div>
      </div>
    </div>
    <div className="spacer" />

    <div className="">
      <img src={laughing} alt="Laughing Emoji" height={200} width={200} />
      <img src={flairSticker} alt="Flair Logo" height={200} width={200} />
      <img src={sayLess} alt="Say Less Text" height={200} width={200} />
    </div>
    <SDKFooter />
  </div>
);
export default Home;
