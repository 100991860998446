const Feature = ({ imgSrc, altText, title, body }) => {
  console.log("qhat", imgSrc);
  return (
    <div>
      {/* <div>this is a {imgSrc}</div> */}
      <div className="header-big center white">{title}</div>
      <div className="body-text center white">{body}</div>
    </div>
  );
};

export default Feature;
