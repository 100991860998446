const Feature = (props) => {
  console.log("direction", props.direction);

  return props.direction === "left" ? (
    <div className="flex-me flair-feature zz-top">
      <div>
        <div className={"flair-feature-header " + props.direction}>
          Fresh Content
        </div>
        <div className="flair-feature-body left">
          A constantly updated and carefully selected library of on-trend
          videos, graphics, and font collections, with full access to stock
          photography on Unsplash and Pixabay.
        </div>
      </div>
      <div className="temp-image"></div>
    </div>
  ) : (
    <div className="flex-me flair-feature zz-top">
      <div className="temp-image"></div>
      <div>
        <div className="flair-feature-header right">Fresh Content</div>
        <div className="flair-feature-body right">
          A constantly updated and carefully selected library of on-trend
          videos, graphics, and font collections, with full access to stock
          photography on Unsplash and Pixabay.
        </div>
      </div>
    </div>
  );
};

export default Feature;
