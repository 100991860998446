import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBlBsPE5qXt7JGHEsxUKqtSLwzWwPS7RI4",
  authDomain: "stickerdesignkit-production.firebaseapp.com",
  databaseURL: "https://stickerdesignkit-production.firebaseio.com",
  projectId: "stickerdesignkit-production",
  storageBucket: "stickerdesignkit-production.appspot.com",
  messagingSenderId: "367040212403",
  appId: "1:367040212403:web:54b9acb275f475b5eaefc6",
  measurementId: "G-PP76Z12MV1",
};

firebase.initializeApp(firebaseConfig);

export default firebase.firestore();
